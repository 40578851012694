import React, { useEffect, useState } from "react";
import './article.css';
import { useParams } from 'react-router';
import { setStyleProperty } from "../../extensions/utils";

const Article = () => {
    const { name } = useParams();
    const [fileContent, setFileContent] = useState("");

    useEffect(() => {
        setStyleProperty('--block-content-backround-img', ``);
        fetch(`/articles/${name}.html`)
            .then(response => {
                if (!response.ok) {
                    setFileContent('Статья не найдена');
                }
                return response.text();
            })
            .then(data => setFileContent(data))
            .catch(error => setFileContent("Ошибка: " + error.message));
    }, [name]);
    
    return <div>
        <div dangerouslySetInnerHTML={{ __html: fileContent }}></div>
    </div>    
}

export default Article;