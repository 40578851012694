import React from "react";

import './spinner.css';

const Spinner = () => {
    return <div className="spinner-main">
        <img src="/images/spin32.png" className="spinner-img"/>
    </div>
}

export default Spinner;