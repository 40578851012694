import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Button, Col, message, Row } from 'antd';
import { setStyleProperty } from '../../extensions/utils';
import { serverFetch } from '../../server';
import './history.css';
import HistoryAbon from './history-abon';
import HistoryServices from './history-services';
import { useWindowWidth } from '@react-hook/window-size';
import { Spinner } from '../../controls';
import HistoryDebet from './history-debet';
import PayItem from '../pay-item/pay-item';
import Auth from '../auth/auth';

const History = () => {
    const user = useSelector((s) => s.user);
    const [history, setHistory] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [externalProducts, setExternalProducts] = useState([]);
    const onlyWidth = useWindowWidth();
    const company = useSelector((s) => s.company);
    const [client, setClient] = useState();

    const [payItem, setPayItem] = useState();

    const getClient = () => serverFetch('account/Info', { method: 'GET'}, user)
        .then((data) => setClient(data))
        .catch((e) => message.error('Ошибка получения информации о клиенте'));

    const getData = () => {
        if (!user) return;
        //console.log("Загрузка");
        //console.log(client);
        if (!client || !(client.email)) return;
        setLoading(true);
        serverFetch(`client/gethistory`, { method: 'GET'}, user)
        .then((data) => {
            //console.log(data);
            if (data && !data.getHistory) {
                data.historyBody = JSON.parse(data.historyBody);
                if (data.historyBody && data.historyBody.memberships && data.historyBody.memberships.length > 0)
                    setSelectedItem(data.historyBody.memberships[0]);
            }
            //console.log(data);
            setHistory(data);
            setLoading(false);
        })
        .catch((e) => {
            console.log('Ошибка получения истории клиента ' + e.message);
            setLoading(false);
        });
    }

    useEffect(() => company && serverFetch(`product`, {method: 'GET', queryParams: {companyId: company.id, onlyExternal: true}}, user)
            .then((data) => setExternalProducts(data))
            .catch((e) => message.error('Ошибка получения товаров и услуг')),
        [company]
    )

    useEffect(() => {
        getData();
        company && setStyleProperty('--block-content-backround-img', `url("${company.catalogBackgroundImage}")`);
        //console.log(company);
    }, [company,client]);

    useEffect(() => {
        getData();
        user && getClient();
        if (!user) setLoading(false);
    }, [user]);

    const onPayItem = (item) => setPayItem(item)

    const canPayVidCard = (id) => 
        company.payDebet && externalProducts.findIndex(i => i.externalId == id) >= 0
    
    const onItemWithAutopay = (item) => {
        const item2 = {...item};
        const prod = externalProducts.find(i => i.externalId == item.idVidCard)
        if (prod) item2.autopay = prod.autopay;
        return item2;
    }

    const divAbons = history && history.historyBody && history.historyBody.memberships 
        && history.historyBody.memberships.map(item => 
            onlyWidth > 744 ? <HistoryAbon item={onItemWithAutopay(item)} key={item.idAbon} onSelect={setSelectedItem} selected={item == selectedItem} onPayItem={onPayItem} canPay={canPayVidCard(item.idVidCard)}/> : 
            <div>
            <HistoryAbon item={onItemWithAutopay(item)} key={item.idAbon} onSelect={setSelectedItem} selected={item == selectedItem} canPay={canPayVidCard(item.idVidCard)} onPayItem={onPayItem}/>
            {selectedItem && selectedItem.idAbon == item.idAbon && <HistoryServices title={selectedItem.name} items={selectedItem.services} visits={selectedItem.visits} key="history-detail"/>}
            </div>        
        )

    const divServices = selectedItem && <HistoryServices title={selectedItem.name} items={selectedItem.services} visits={selectedItem.visits}/>

    const divDebet = history && history.historyBody && <div>
        <HistoryDebet key={"history_debet"} debet={history.historyBody.debet} services={history.historyBody.debet_services} onSelect={setSelectedItem} selected={!selectedItem}/>
    </div>

    const divBody = onlyWidth > 744 ? <div className='history'><Row>
            <Col span={12}><>{divDebet}{divAbons}</></Col>
            <Col span={12}>{divServices}</Col>
            </Row>
        </div> : <>{divDebet}{divAbons}</>

    return <>
    {client && !(client.email) && <Alert type="warning" message="Укажите адрес почты в профиле пользователя 'Мои данные' и обновите страницу для просмотра истории" showIcon />}
    {loading ? <Spinner /> : divBody}
    {payItem && <PayItem onCancel={() => setPayItem()} item={payItem}/>}
    {!user && <Auth open={true} />}
    </>
}

export default History;