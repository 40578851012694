// https://zalki-lab.ru/react-js-component-timer-countdown/
import React from "react";

const CountDown = ({ seconds = 0, callBack }) => {
    const [over, setOver] = React.useState(false);
    const [s, setTime] = React.useState(seconds);
  
    const tick = () => {
      if (over) return;
      if (s === 0) {
        callBack();
        setOver(true);
      } else {
        setTime(s - 1);
      }
    };
  
    React.useEffect(() => {
      console.log("Запуск таймера");
      const timerID = setInterval(() => {
        console.log("Таймер сработал");
        tick();
      }, 1000);
      return () => {
        console.log("Останов таймера");
        clearInterval(timerID);
      }
    });
  
    return (
      <div>{`Запросить код повторно через 00:${s.toString().padStart(2, '0')}`}</div>
    );
  };

  export default CountDown;