import { Button, Card, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import './history-abon.css';
import HistoryStatus from "./history-status";

const HistoryAbon = ({ ...props }) => {
    const { item, onSelect, selected, onPayItem, canPay } = props;
    const classes = "history-abon-title " + (selected && "history-abon-title-selected");
    return <div className="history-abon-card" onClick={() => onSelect(item)}>
        <div className={classes}>{item.name}</div>
        <div className="history-abon-body">
            <div className="history-abon-item">Дата покупки: {dayjs(item.purchaseDate).format("DD.MM.YYYY")}</div>
            <div className="history-abon-item">Стоимость: {item.purchaseSyma.toLocaleString()} ₽ 
                {item && item.debet != 0 && <span style={{marginLeft: 8}}><Tag color="#f50">Долг {item.debet.toLocaleString()} ₽</Tag>
                <Button onClick={() => onPayItem(item)} disabled={!canPay}>Оплатить</Button></span>}
            </div>
            <div className="history-abon-item">Статус: <HistoryStatus id ={item.status}/></div>
            {item && item.visitRest && item.visitRest > 0 && <div className="history-abon-item">Остаток: {item.visitRest}</div>}
            <div className="history-abon-item">Окончание: {item.finishDate && dayjs(item.finishDate).format("DD.MM.YYYY")}</div>
            
        </div>
    </div>
}

export default HistoryAbon;