import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Button, message } from 'antd';
import { UserOutlined, LockOutlined, HomeOutlined } from '@ant-design/icons';
import Auth from '../auth/auth.js';
import Account from '../account/account';
import { userLoaded, setCurrentNavMenu } from '../../redux/actions';
import { useWindowWidth } from '@react-hook/window-size';
import { serverFetch } from '../../server';
import './nav-menu.css';

function getItem(label, key) {
    return {key,label,};
}

const NavMenu = () => {
    const d = useDispatch();
    const current = useSelector((s) => s.currentNavMenu);
    const user = useSelector((s) => s.user);
    const onlyWidth = useWindowWidth();
    const [navItems, setNavItems] = useState([]);
    const [showSignin, setShowSignin] = useState(false);
    const [showAccountInfo, setShowAccountInfo] = useState(false);
    const company = useSelector((s) => s.company);

    const divMain = onlyWidth  > 744 ? <div>ГЛАВНАЯ</div> : <HomeOutlined />
    const isFrame = window.self !== window.top;
    const defaultItems = [
        isFrame || getItem(<Link to='/home'>{divMain}</Link>, 'home'),
        getItem(<Link to='/catalog'>УСЛУГИ</Link>, 'catalog'),
    ];    

    const logout = () => {
        serverFetch(`account/logout`, { method: 'POST' }, user)
            .then(() => { d(userLoaded()); })
            .catch(() => { message.error('Ошибка выхода из системы'); });
    };
    
    useEffect(() => {
        //console.log(user);
        let tempItems = [...defaultItems];
        if (company && company.hasSchedule) tempItems.push(getItem(<Link to='/calendar'>РАСПИСАНИЕ</Link>, 'calendar'));
        tempItems.push(getItem(<Link to='/purchases'>ПОКУПКИ</Link>, 'purchases'));
        if (company && company.hasHistory) tempItems.push(getItem(<Link to='/history'>ИСТОРИЯ</Link>, 'history'));
        setNavItems(tempItems);
    }, [user,company]);

    const handleClick = (e) => {
        d(setCurrentNavMenu(e.key));
    };

    const items = [
        {
            key: '1',
            label: ( <Button type='link' className='account-item-btn' onClick={() => setShowAccountInfo(true)} size='middle'>Мои данные</Button> ),
        },
        {
            key: '2',
            label: ( <Button type='link' className='account-item-btn' onClick={() => logout()} size='middle'>Выход</Button> ),
        },
    ];

    return (
        <>
            <div className='nav-menu-container'>
                <Menu
                    className='nav-menu'
                    key='menu'
                    onClick={handleClick}
                    selectedKeys={[current]}
                    mode='horizontal'
                    theme='light'
                    disabledOverflow={true}
                    items={navItems}
                ></Menu>
                <>
                    {user ? (
                        <Dropdown menu={{ items: items }} placement='bottomRight'>
                            <div className='account-btn nav-menu-account-btn'><UserOutlined/><span>{onlyWidth > 744 && (user.userName || user.userLogin)}</span></div>
                            {/* <Button type='link' className='account-btn' size='middle'>
                                <UserOutlined/>{onlyWidth > 744 && (user.userName || user.userLogin)}
                            </Button> */}
                        </Dropdown>
                    ) : (
                        <Button type='link' className='account-btn' onClick={() => setShowSignin(true)} size='middle' icon={<LockOutlined />}>
                            {onlyWidth > 744 && 'Личный кабинет'}
                        </Button>
                    )}
                </>
            </div>
            {showSignin && <Auth open={showSignin} onClose={() => setShowSignin(false)} />}
            {showAccountInfo && <Account open={showAccountInfo} onClose={() => setShowAccountInfo(false)} />}
        </>
    );
};

export default NavMenu;