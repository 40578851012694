import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';
import { Input, Form, Modal, Button, DatePicker, Checkbox, message } from 'antd';
import { useWindowWidth } from '@react-hook/window-size';
import { serverFetch } from '../../server';
import './account.css';

const Account = ({ ...props }) => {
    const user = useSelector((s) => s.user);
    const { open, onClose } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState({});
    const [confirmStatus, setConfirmStatus] = useState(0); // 0 - не начато, 1 - введен емаил, 2 - отправлено письмо, 3 - подтверждён
    const [formValid, setFormValid] = useState(false);

    useEffect(() => form.validateFields({validateOnly: true,})
        .then(() => setFormValid(true))
        .catch(() => setFormValid(false))
    , [client])

    useEffect(() => {
        getData();
        return () => { close(); };
    }, []);

    const getData = () => {
        setLoading(true);
        serverFetch('account/info', { method: 'GET' }, user)
            .then((data) => {
                setClient({ ...data, birthday: data.birthday && dayjs(moment(data.birthday)) });
                form.setFieldsValue({
                    fullName: data.fullName,
                    userLogin: data.userLogin,
                    email: data.email,
                    gender: data.gender,
                    externalId: data.externalId,
                    autopayCard: data.autopayCard,
                });
                setLoading(false);
            })
            .catch((e) => {
                message.error('Ошибка получения пользователя');
                setLoading(false);
            });
    };

    const close = () => {
        setLoading(false);
        form.resetFields();
        onClose();
    };

    const onFinish = () => {
        setLoading(true);
        serverFetch(`client`, { method: 'PUT', bodyData: client }, user)
            .then(() => { close(); })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onAutopayCancel = () => {
        if (window.confirm("Отменить подписку?")){
            setLoading(true);
            serverFetch("account/AutopayCancel", {method: "POST"}, user)
                .then(getData)
                .catch((e) => {
                    setLoading(false);
                    e.UserMessage && message.error("Ошибка " + e.UserMessage);
                })
        }
    }

    // const onConfirmEmail = () => {
    //     serverFetch(`account/confirmemail`, { method: 'POST', bodyData: client }, user)
    //         .then(() => {
    //             message.success('На вашу почту отправлено письмо для подтверждения');
    //         })
    //         .catch((e) => {
    //             e.UserMessage && message.error(e.UserMessage);
    //         });
    // };

    const onValuesChange = (value) => {
        if (value.email) setConfirmStatus(1);
        setClient({ ...client, ...value });
    };

    const startConfirm = () => {
        serverFetch(`account/StartEmailConfirm?sessionId=${user.id}&email=${form.getFieldValue("email")}`, { method: 'POST' }, user)
                .then(() => setConfirmStatus(2))
                .catch((e) => {
                    e.UserMessage && message.error(e.UserMessage);
                });
    }

    const checkConfirm = () => {
        serverFetch(`account/CheckEmailConfirm?sessionId=${user.id}&code=${form.getFieldValue("code")}&email=${form.getFieldValue("email")}`, { method: 'POST' }, user)
                .then(() => setConfirmStatus(3))
                .catch((e) => {
                    e.UserMessage && message.error(e.UserMessage);
                });
    }

    const divCard = client.autopayCode && <>
        <Form.Item label='Автопродление'><Input size='middle' name='autopayCard' disabled={true} value={client.autopayCard}/></Form.Item>
        <Form.Item label=' '><Button onClick={onAutopayCancel}>Отменить подписку</Button></Form.Item>
        
        </>
    return (<>
        <Modal
            forceRender
            open={open}
            showTitle={false}
            closable={false}
            closeOnOutsideClick={false}
            footer={null}
            width={540}
            onCancel={() => onClose()}
            styles={{ margin: '-4px 16px 0' }}
        >
            <div className='auth-title'>Мои данные</div>

            <Form
                colon={false}
                form={form}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                <Form.Item label='ФИО' name='fullName'>
                    <Input size='middle' name='fullName' disabled={loading} maxLength={100}/>
                </Form.Item>
                <Form.Item label='Телефон'>
                    <>{client.userLogin}</>
                </Form.Item>
                <Form.Item label='Дата рождения'>
                    <DatePicker
                        allowClear={true}
                        format="DD.MM.YYYY"
                        value={client && client.birthday}
                        onChange={(dateX, date) => {
                            if (!date) {
                                setClient({ ...client, birthday: null });
                                return;
                            }

                            var birthdayDate =
                                date &&
                                moment(date, 'DD.MM.YYYY').utcOffset(0, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

                            setClient({ ...client, birthday: dayjs(birthdayDate) });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label='Почта'
                    disabled={loading}
                    name='email'
                    validateFirst={true}
                    rules={[{ type: 'email', message: 'Неверный формат Email' }]}
                >
                    <Input name='email' disabled={loading || confirmStatus == 2} maxLength={100}/>
                </Form.Item>
                {formValid && confirmStatus == 1 && <Form.Item label={<span></span>}>
                    <div className='btn-container' style={{ margin: 0 }}>
                        <Button className='auth-btn' size='middle' disabled={loading} onClick={startConfirm} >Подтвердить</Button>
                    </div>
                </Form.Item>}

                {confirmStatus == 2 && <><Form.Item label='' wrapperCol={{ span: 24 }}>
                    На указанную почту было отправлено письмо. Для подтверждения введите код из этого письма.
                </Form.Item>
                <Form.Item label="Код подтверждения" name="code" >
                    <Input  maxLength={100}/>
                </Form.Item>
                <Form.Item label={<span></span>}>
                    <div className='btn-container' style={{ margin: 0 }}>
                        <Button className='auth-btn' size='middle' disabled={loading} onClick={checkConfirm}>Проверить</Button>
                    </div>
                </Form.Item>
                </>}

                {divCard}
                <Form.Item>
                    <div className='btn-container'>
                        <Button className='auth-btn' size='middle' onClick={() => onClose()} disabled={loading}>
                            Назад
                        </Button>
                        <Button className='auth-btn' size='middle' loading={loading} htmlType='submit' disabled={confirmStatus == 1 || confirmStatus == 2}>
                            Сохранить
                        </Button>
                    </div>
                </Form.Item>
                <label className='prompt'>Нажимая на кнопку "Сохранить", вы даете согласие на обработку <a href="/article/agreement" target='_blank'>персональных данных</a> и соглашаетесь  
                    с <a href="/article/rules" target='_blank'> Условиями и правилами</a> сервиса</label>
            </Form>
        </Modal>
        </>
    );
};

export default Account;
