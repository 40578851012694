import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, DatePicker, Select, message, Checkbox, Space, Button } from 'antd';
import {qtyClients} from '../../extensions/helper';
import { serverFetch } from '../../server';
import moment from 'moment';
import dayjs from 'dayjs';

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day').add(-1, 'day');
};

const defaultSale = {
    qtyClients: 1,
    date: dayjs(moment().utcOffset(0, true).set({ hour: 24, minute: 0, second: 0, millisecond: 0 })),
    rent: false,
}

const Booking = ({ item, setCurrentItem, setCurrentRents }) => {
    const { Option } = Select;  
    const company = useSelector((s) => s.company);
    const user = useSelector((s) => s.user);
    const [form] = Form.useForm();
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [times, setTimes] = useState([]);
    const [seansTovs, setSeansTovs] = useState([]);
    const [rentItems, setRentItems] = useState([]);

    useEffect(() => {
        form.setFieldsValue(defaultSale);
        if (company) {
            let cleanup = false;
            const fetchData = async () => {
                setLoading(true);
                let queryParams = {
                    companyId: company.id,
                    isActive: true,
                    productId: item.productId
                };
                let promises = [
                    await serverFetch('room', { method: 'GET', queryParams: queryParams }, user)
                        .then((data) => {
                            return data;
                        })
                        .catch((e) => {
                            message.error('Ошибка чтения залов');
                            console.log(e);
                            setLoading(false);
                        }),
                ];
                Promise.all([promises]).then((result) => {
                    if (cleanup) return;
                    setRooms(result[0][0] || []);
                    if (result[0][0].length > 0) 
                    {
                        form.setFieldsValue({ roomId: result[0][0][0].id });
                    };
                    getTimes();
                    setLoading(false);
                });
            };
            fetchData();
            return () => (cleanup = true);
        }
    }, []);

    const onValuesChange = (value) => {
        console.log(value);
        if (value.roomId || value.date) getTimes();
        calcPrices();
    }

    const getTimes = () => {
        const sale = form.getFieldsValue();
        let queryParams = {
            companyId: company.id,
            roomId: sale.roomId,
            qtyClients: sale.qtyClients,
            date: sale.date,
            productId: item.productId
        };
        serverFetch('room/times', { method: 'GET', queryParams: queryParams }, user)
            .then((data) => {
                console.log("getTimes->", data);
                setTimes(data);
                const currentTimeId = sale.timeId;
                if (data.length == 0) { 
                    form.setFieldsValue({timeId: null}); 
                    calcPrices();
                }
                else if (!data.find(el => el.id == currentTimeId) ) {
                    form.setFieldsValue({timeId: data[0].id});
                    calcPrices();
                    getSeansTov();
                }
                setLoading(false);
            })
            .catch((e) => {
                message.error('Ошибка получение интервалов');
                console.log(e);
                setLoading(false);
            })
    }

    const getSeansTov = () => {
        const roomSeansId = form.getFieldValue("timeId");
        if (item.productDuration == 0 && roomSeansId) {
            serverFetch(`room/SeansProducts/${roomSeansId}`, { method: 'GET'}, user)
                .then((data) => {
                    setSeansTovs(data);
                })
                .catch((e) => {
                    message.error('Ошибка получение услуг сеанса');
                    console.log(e);
                    setLoading(false);
                });
        }
        else setSeansTovs([]);
    }

    const calcPrices = (items = rentItems) => {
        setCurrentRents(items);
        // Получение стоимости и комиссии
        // Если не заполнен прокат то не пытаемся считать
        if (items.find(i => !i.productId || !i.size)){
            form.setFieldValue('totalPrice', null);
            form.setFieldValue('commission', null);
            const currentItem = {
                productId: item.productId,
                qty: form.getFieldValue('qtyClients'),
                price: 0,
                totalPrice: null, 
                commission: null, 
                qtyClients: form.getFieldValue('qtyClients'),
                startMin: form.getFieldValue('timeId'),
                date: form.getFieldValue('date'),
                roomId: form.getFieldValue('roomId'),     
                catalogId: item.id,                 
            };
            setCurrentItem(currentItem)
        }
        else {
            const formObj = form.getFieldsValue();
            const queryParams = {
                companyId: company.id,
                qtyClients: formObj.qtyClients,
                date: formObj.date,
                productId: item.productId,
                catalogId: item.id,
                startMin: form.getFieldValue('timeId') ?? 0,
                rentProductIds: items.map(i => i.productId),
            };
            form.setFieldValue('totalPrice', null);
            serverFetch('sale/getprice', { method: 'POST', bodyData: queryParams}, user)
                .then((data) => {
                    form.setFieldValue('totalPrice', data.price);
                    form.setFieldValue('commission', data.commission);
                    const currentItem = {
                        productId: item.productId,
                        qty: form.getFieldValue('qtyClients'),
                        price: 0,
                        totalPrice: form.getFieldValue('timeId') == null ? null : data.price, 
                        commission: form.getFieldValue('timeId') == null ? null : data.commission, 
                        qtyClients: form.getFieldValue('qtyClients'),
                        startMin: form.getFieldValue('timeId'),
                        date: form.getFieldValue('date'),
                        roomId: form.getFieldValue('roomId'),       
                        catalogId: item.id,             
                    };
                    setCurrentItem(currentItem)
                })
                .catch((e) => {
                    message.error('Ошибка получение стоимости');
                    console.log(e);
                })
        }
    }

    // #region Прокат
    let rentTovs = [];
    seansTovs.forEach(i => {
        if (i.qty > 0 && !rentTovs.find(j => j.value == i.productId))
            rentTovs.push({value: i.productId, label: i.productName, key: i.productId });
    })
    
    const formObj = form.getFieldsValue();
    
    // Команды проката
    const onRentAdd = () => {
        const newA = [...rentItems, {}];
        setRentItems(newA);
        calcPrices(newA);
    }
    const onRentDel = async () => {
        let newA = [...rentItems];
        if (newA.length > 0) {
            newA.pop();
            setRentItems(newA);
            calcPrices(newA);
        }
    }
    const onRentProductSelect = (idx, value) => {
        const newRentItems = [...rentItems];
        newRentItems[idx].productId = value;
        setRentItems(newRentItems);
        onRentSizeSelect(idx, null);
    }

    const onRentSizeSelect = (idx, value) => {
        const newRentItems = [...rentItems];
        newRentItems[idx].size = value;
        setRentItems(newRentItems);
        calcPrices();
    }

    // Выбран прокат
    const rent = rentItems.length > 0;

    // Есть оборудование для проката
    const enableRent = formObj.date && formObj.timeId && formObj.roomId && seansTovs.filter(d => d.qty > 0).length > 0;

    const divRents = rentItems.map((i,idx) => {
        const rentSizes = seansTovs.filter(i2 => i2.qty > 0 && i2.productId == i.productId).map(i2 => {return {value: i2.size, label: i2.size, key: i2.size}});
        return <Form.Item label={`Инв. ${idx + 1}`} key={`rent_${idx}`} >
            <Space>
                <Form.Item hasFeedback validateStatus={i.productId ? '' : 'error'}><Select style={{width: 200}} options={rentTovs} onSelect={(value) => onRentProductSelect(idx,value)} placeholder="Оборудование"/></Form.Item>
                <Form.Item label="Размер" hasFeedback validateStatus={i.size ? '' : 'error'}><Select style={{width: 85}} options={rentSizes} onSelect={(value) => onRentSizeSelect(idx,value)}/></Form.Item>
            </Space>
        </Form.Item>
    })

    const divRent = enableRent && <>
        <Form.Item label="Прокат">
            <Space>
                <Button onClick={onRentAdd}>Добавить</Button>
                <Button onClick={onRentDel}>Удалить</Button>
            </Space>
        </Form.Item>
        {divRents}
    </>

    //console.log(rentItems);
    // #endregion

    return (
        <>
            <div style={{ marginTop: 16, marginBottom: 16 }}>{item && item.description}</div>
            <Form colon={false} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} form={form} onValuesChange={onValuesChange}>
                <Form.Item label='Зал' name="roomId">
                        <Select placeholder='Выберите зал' disabled={loading || rent}>
                            {rooms.map((p) => <Option key={`room}_${p.id}`} value={p.id}>{p.name}</Option>)}
                        </Select>
                </Form.Item>
                <Form.Item label='Посетителей' wrapperCol={{span: 6}} min={1} max={10} name="qtyClients">
                    <Select disabled={loading || !item.productDuration || rent}>
                            {qtyClients.map((p) => <Option key={`time}_${p}`} value={p}>{p}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label='Дата' name="date">
                    <DatePicker style={{ width: '100%' }} allowClear={false} format="DD.MM.YYYY" disabledDate={disabledDate} disabled={rent} />
                </Form.Item>
                <Form.Item label='Время' name="timeId">
                        <Select placeholder='Выберите время' disabled={loading || rent}>
                            {times.map((p) => <Option key={`time}_${p.id}`} value={p.id}>{p.name}</Option>)}
                        </Select>
                </Form.Item>
                {divRent} 
                <Form.Item label='Комиссия' wrapperCol={{span: 6}} name="commission">
                    <Input size='middle' disabled/>
                </Form.Item>
                <Form.Item label='Стоимость' wrapperCol={{span: 6}} name="totalPrice">
                    <Input size='middle' disabled/>
                </Form.Item>
            </Form>
        </>
    );
};

export default Booking;