import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, Modal, Button, message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { MaskedInput } from 'antd-mask-input';
import { userLoaded } from '../../redux/actions';
import { serverFetch } from '../../server';
import CountDown from '../count-down';
import './auth.css';
import { authvk } from '../../extensions/auth-vk';
import { getIsVkSession, getVkSession } from '../../extensions/utils';

const Auth = ({ ...props }) => {
    const d = useDispatch();
    const { open, onClose } = props;
    const [form] = Form.useForm();
    const [signinLoading, setSigninLoading] = useState(false);
    const [signupLoading, setSignupLoading] = useState(false);
    const [authData, setAuthData] = useState({});
    const [codeSent, setCodeSent] = useState(false);
    const [coolDownDone, setCoolDownDone] = useState(false);
    const [codeSent2, setCodeSent2] = useState(false);
    const company = useSelector((s) => s.company);
    const [showOtherWay, setShowOtherWay] = useState(false);
    const user = useSelector((s) => s.user);
    const [logining, setLogining] = useState(false);
    const [intervaled, setIntervaled] = useState();
    const history = useHistory();

    useEffect(() => user && onClose(), [user]);

    useEffect(() => {
        if (!open) form.resetFields();
    }, [form, open]);

    const onFinish = () => {
        let data = {
            userLogin: authData.phone,
            password: authData.password,
        };
        if (authData.isSignIn) signin(data);
        else signup(data);
    };

    const signin = (data) => {
        setSigninLoading(true);
        serverFetch(`account/signin`, { method: 'POST', bodyData: data })
            .then((data) => {
                if (data) {
                    d(userLoaded(data));
                    setSigninLoading(false);
                    onClose && onClose();
                } else {
                    message.error('Ошибка авторизации');
                    setSigninLoading(false);
                }
            })
            .catch((e) => {
                setSigninLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const signup = (data) => {
        setSignupLoading(true);
        serverFetch(`account/signup`, { method: 'POST', bodyData: data })
            .then((data) => {
                setSignupLoading(false);
                //onClose();
                message.success('Ваш пароль для входа отправлен на указанный номер телефона.');
                setCodeSent(true);
            })
            .catch((e) => {
                setSignupLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onValuesChange = (value) => {
        if (value.phone === '+8 ___ ___ __ __' || value.phone === '+9 ___ ___ __ __'){
            value.phone = '+7 ___ ___ __ __';
            form.setFieldValue('phone', value.phone);
        }
        else 
        {
            //console.log(value);
            setAuthData({ ...authData, ...value });
        }
    };

    const onSignin = () => {
        setAuthData({ ...authData, isSignIn: true });
        form.validateFields();
        form.submit();
    };

    const onSignup = () => {
        setSignupLoading(true);
        form.setFieldValue('password', '');
        serverFetch(`account/signupEmail`, { method: 'POST', bodyData: authData.phone })
            .then((data) => {
                setSignupLoading(false);
                //onClose();
                message.success(data);
                setCodeSent(true);
            })
            .catch((e) => {
                setSignupLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onSignup2 = () => {
        setSignupLoading(true);
        serverFetch(`account/signupEmail2`, { method: 'POST', bodyData: authData.phone })
            .then((data) => {
                setSignupLoading(false);
                //onClose();
                message.success(data);
                setCodeSent2(true);
            })
            .catch((e) => {
                setSignupLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const disableEnter = signupLoading || !authData.phone || authData.phone.indexOf('_') >= 0;

    const divOtherWay = logining || (showOtherWay ?
    <>
        <Form.Item
            requiredMark='optional'
            label='Телефон'
            name='phone'
            rules={[{ required: true, message: 'Пожалуйста введите номер телефона' }]}
        >
            <MaskedInput size='middle' mask={'+0 000 000 00 00'} name='phone' disabled={signinLoading || signupLoading}/>
        </Form.Item>

        <Form.Item label='Код доступа' name='password'>
            <Input.Password size='middle' name='password' disabled={signinLoading || signupLoading} />
        </Form.Item>
        
        <Form.Item>
            <div className='btn-container-auth'>
                <Button className='auth-btn' size='middle' onClick={() => onClose && onClose()} disabled={signinLoading}>Назад</Button>
                <Button className='auth-btn' size='middle' disabled={disableEnter} loading={signinLoading} onClick={() => onSignin()}>Войти</Button>
            </div>
        </Form.Item>
        <Form.Item>
            {!codeSent && <Button type='link' size='middle' onClick={() => onSignup()} loading={signupLoading} disabled={disableEnter} block={true}>Получить код доступа</Button>}
            {coolDownDone && codeSent && !codeSent2 && 
            <Button type='link' size='middle' onClick={() => onSignup2()} loading={signupLoading} disabled={disableEnter} block={true}>
                    Отправить код доступа повторно</Button>}
            {!coolDownDone && codeSent && <CountDown seconds={45} callBack={() => setCoolDownDone(true)}/>}
            {/* <div className='prompt'>Новый код доступа будет отправлен в СМС</div> */}
        </Form.Item>
        <Form.Item>
            <div style={{fontSize: 12}}>Нажимая кнопку Получить код доступа и Войти, вы соглашаетесь с условиями <a href='/article/agreement' target='_blank'>политики конфиденциальности</a></div>
        </Form.Item>
    </>
    : <Form.Item><div className='auth-or' onClick={() => setShowOtherWay(true)}>Войти другим способом</div></Form.Item>)

    const startR = (state) => {
        //console.log(state);
        const interval = setInterval(() => {
            serverFetch(`vk/session?state=${state}`)
                .then((data) => {
                    if (data){
                        //console.log(data);
                        clearInterval(interval);
                        d(userLoaded(data));
                        onClose();
                    }
                })
                .catch(e => {
                    console.log(`Ошибка ${e.message}`);
                    clearInterval(interval);
                });
          }, 1000); // Интервал 1 секунда (1000 миллисекунд)
        setIntervaled(interval)
    }

    const isVkSession = getIsVkSession();

    const startVkLogin = (domain, provider) => {
        if (provider !== "vkid" && isVkSession) return;
        setLogining(true);
        if (isVkSession) {
            const bodyData = JSON.parse(atob(getVkSession()));
            serverFetch(`account/signinvk`, { method: 'POST', bodyData })
                .then(data => {
                    //setLogining(false);
                    d(userLoaded(data));
                })
                .catch(e => {
                    setLogining(false);
                    console.log(e);
                    message.error('Ошибка входа. Попробуйте перегрузить страницу и предоставить доступ приложению до данных почты и телефона');
                });
        }
        else {
            authvk(domain, provider, startR);
        }
    }
    
    const imgClassName = isVkSession ? 'auth-img-vk-disabled' : 'auth-img-vk-enabled';
    return (
        <Modal
            forceRender
            open={open}
            title=''
            closable={false}
            footer={null}
            width={400}
            onCancel={() => {
                setLogining(false);
                onClose ? onClose() : history.goBack();
                if (intervaled) clearInterval(intervaled);
            }}
            styles={{ margin: '-4px 20px 0' }}
        >
            <div className='auth-title'>Войти или зарегистрироваться</div>
            <Form
                colon={false}
                form={form}
                onFinish={onFinish}
                layout='vertical'
                onValuesChange={onValuesChange}
            >
                {logining || <Form.Item>
                    <div className='auth-btns-ss'>
                        <img src='/images/vk.svg' className='auth-img-vk-enabled' onClick={() => startVkLogin(company.domain, "vkid")} />
                        <img src='/images/ok.svg' className={imgClassName} onClick={() => startVkLogin(company.domain, "ok_ru")}/>
                        <img src='/images/mail.svg' className={imgClassName} onClick={() => startVkLogin(company.domain, "mail_ru")}/></div>
                </Form.Item>}
                {divOtherWay}
                {logining && <div className='auth-waiting'><Spin spinning/></div>}                
            </Form>
        </Modal>
    );
};

export default Auth;