import React from "react";
import { Button, Checkbox, Tag } from "antd";
import './history-abon.css';
import './history-debet.css';

const HistoryDebet = ({...props}) => {
    const {debet, services, selected, onSelect} = props;

    const classes = "history-abon-title " + (selected && "history-abon-title-selected");
    if (!debet || debet == 0) return <></>

    const divItems = selected && <div className="history-abon-body">{services.map(item => <div className="history-abon-item" key={item.idDoc}>
        <Checkbox checked={true}>{item.tovName} <Tag>{item.syma} ₽</Tag></Checkbox>
        </div>)}
        {/* <div className="history-debet-bottom"><Button>Оплатить</Button></div> */}
    </div>

    return <div className="history-abon-card" onClick={() => onSelect(null)}>
        <div className={classes}>Долг {debet} ₽</div>
        {divItems}
    </div>}

export default HistoryDebet;