import { Spin } from "antd";
import React, { useEffect } from "react";
import { serverFetch } from "../../server";

const Vk = () => {    
    const params = new URLSearchParams(window.location.search);
    
    const onCheck = () => {
        serverFetch(`vk/token?code=${params.get('code')}&device_id=${params.get('device_id')}&state=${params.get('state')}&client_id=${process.env.REACT_APP_VK_CLIENT_ID}`)
            .then(() => {
                //localStorage.setItem('auth', JSON.stringify(data));
                window.close();
            })
            .catch(e => console.log(e.message));
    }

    useEffect(onCheck, []);

    return<div>
        <div>Авторизация ВКонтакте</div>
        <Spin spinning/>
    </div>
}

export default Vk;