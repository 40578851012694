import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Row, Space, message } from 'antd';
import { serverFetch } from '../../server';

const Subscription = ({ item, setCurrentItem }) => {
    const company = useSelector((s) => s.company);
    const [codeData, setCodeData] = useState();
    const [loading, setLoading] = useState(false);
    const user = useSelector((s) => s.user);
    const [promoApplied, setPromoApplied] = useState(false);

    let totalPrice = item.price;
    const commission = Math.round(company && company.prcCommission > 0 ? totalPrice*company.prcCommission : 0)/100;
    totalPrice = totalPrice + commission;
    const sale = {
        productId: item.productId,
        qty: 1,
        price: item.price,
        totalPrice, 
        commission,
    };
    useEffect(() => setCurrentItem(sale), [company]);

    const divCommission = commission == 0 ? null : <>
        <Row>Комиссия {commission}</Row>
        <Row>ИТОГО {totalPrice}</Row>
    </>

    const getPromocodePrice = () => {
        setLoading(true);
        const askItem = { catalogItemId: item.id, codeData };
        serverFetch('catalog/PromoPrice', { method: 'POST', bodyData: askItem }, user)
                .then((data) => {
                    //console.log(data);
                    //console.log(item);
                    if (data) {
                        let totalPrice = data.price;
                        const commission = Math.round(company && company.prcCommission > 0 ? totalPrice*company.prcCommission : 0)/100;
                        totalPrice = totalPrice + commission;
                        const newSale = {
                            productId: item.productId,
                            qty: 1,
                            price: data.price,
                            totalPrice, 
                            commission,
                            promocodeId: data.promocodeId,
                        };
                        setCurrentItem(newSale)
                        setPromoApplied(true);
                    }
                    else message.error('Промокод не найден');
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    message.error('Ошибка получения цены по промокоду');
                    setLoading(false);
                })
    }

    const divPromo = item && item.qtyPromocodes > 0 && <Row>
        <Space>
            <div style={{color: 'black'}}>Промокод</div>
            <Input onChange={(e) => setCodeData(e.target.value)} value={codeData} disabled={promoApplied}/>
            <Button onClick={getPromocodePrice} disabled={promoApplied}>Применить</Button>
        </Space>
    </Row>
return (
        <>
            {divPromo}
            <div className='catalog-item-price' style={{ marginTop: 16, marginBottom: 16 }}>
                {item.price}
            </div>
            <div>{item && item.description}</div>
            {divCommission}
        </>
    );
};

export default Subscription;