import React from 'react';
import './week-calendar-item.css';
import {cnvMinutesToTimeString} from '../../extensions/utils';

const WeekCalendarItem = ({ ...props }) => {
    const {productName, roomName, sotrName, color, startMin, finishMin, id, comment, onItemClick} = props;
    const colorStyle = { borderLeftColor: color, cursor: 'pointer'};
    const time = `${cnvMinutesToTimeString(startMin)} - ${cnvMinutesToTimeString(finishMin)}`;
    const pName = productName ? productName : comment;
    return (
        <div className='week-calendar-item' style={colorStyle} onClick={()=>onItemClick(id)}>
            {comment ? <div className='product'>{comment}</div> : <div className='product'>{productName}</div>}
            {/* {productName && <div className='product'>{productName}</div>} */}
            <div className='time'>{time}</div>
            <div className='room'>{roomName}</div>
            <div className='sotr'>{sotrName}</div>
        </div>
    );
};

export default WeekCalendarItem;
