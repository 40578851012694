import { Tag } from "antd";
import React from "react";

const HistoryStatus = ({...props}) => {
    const {id} = props;

    switch (id)
    {
        case 1:
            return <Tag color="#dfe300">Новый</Tag>
        case 2:
            return <Tag color="#87d068">Активный</Tag>
        case 3:
            return <Tag color="#2db7f5">Заморожен</Tag>
        case 5:
            return <Tag color="#f50">Закрыт</Tag>
        default:
            return <>{id}</>
    }
}

export default HistoryStatus;