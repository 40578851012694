import React, {useState} from 'react';
import './week-calendar-descriptions.css';

const WeekCalendarDescriptions = ({ ...props }) => {
    const {items } = props;
    const divItems = items.map(i => <div className='week-calendar-description-item' key={i.name}><strong>{i.name}</strong> - {i.description}</div>)
    return (
        <>
        {divItems}
        </>
    );
};

export default WeekCalendarDescriptions;