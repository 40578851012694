import React, {useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, message } from 'antd';
import { serverFetch } from '../../server';

const Certificate = ({ item, disabled, setCurrentItem, setCond }) => {
    const user = useSelector((s) => s.user);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [sale, setSale] = useState();
    const company = useSelector((s) => s.company);

    useEffect(() => {
        serverFetch('account/info', { method: 'GET' }, user)
                .then((client) => {
                    let totalPrice = item.price;
                    const commission = Math.round(company && company.prcCommission > 0 ? totalPrice*company.prcCommission : 0)/100;
                    totalPrice = totalPrice + commission;
                    const sale = {
                        receiverName: client.fullName, 
                        receiverPhone: client.userLogin,
                        receiverEmail: client.email,
                        receiverSendStatus: 0,
                        productId: item.productId,
                        qty: 1,
                        price: item.price,
                        totalPrice, 
                        commission,
                    };
                    if (!sale.receiverEmail)
                        setCond(false);
                    form.setFieldsValue(sale);
                    setSale(sale);
                    setCurrentItem(sale);
                    setLoading(false);
                })
                .catch((e) => {
                    message.error('Ошибка получения клиента');
                    setLoading(false);
                })
    }, []);

    const onValuesChange = (value) => {
        const newSale = { ...sale, ...value };
        if (newSale.receiverEmail) setCond(true);
        else setCond(false);
        setSale(newSale);
        setCurrentItem(newSale);
    };
    return (
        <>
            <div className='catalog-item-price' style={{ marginTop: 16, marginBottom: 16 }}>
                {item.price}
            </div>
            <div>{item && item.description}</div>

            <Form colon={false} form={form} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} onValuesChange={onValuesChange}>
                <Form.Item label='Комиссия' name='commission' wrapperCol={{span: 6}}>
                    <Input size='middle' name='commission' disabled/>
                </Form.Item>
                <Form.Item label='Стоимость' name='totalPrice' wrapperCol={{span: 6}}>
                    <Input size='middle' name='totalPrice' disabled/>
                </Form.Item>

                <div className='certificate-recipient-title'>Получатель</div>
                <Form.Item label='Имя' name='receiverName'>
                    <Input size='middle' name='receiverName' disabled={loading}/>
                </Form.Item>
                <Form.Item label='Телефон' name='receiverPhone'>
                    <Input size='middle' name='receiverPhone' disabled={loading}/>
                </Form.Item>

                <Form.Item label='Эл. почта' name='receiverEmail' disabled={loading} required>
                    <Input disabled={disabled} />
                </Form.Item>
                {sale && !sale.receiverEmail && <Form.Item style={{color: "red"}} label=" ">Введите электронную почту</Form.Item>}
            </Form>
        </>
    );
};

export default Certificate;
