import React from 'react';
import WeekCalendarItem from './week-calendar-item';
import './week-calendar-row-cell.css';

const WeekCalendarRowCell = ({ ...props }) => {
    const {width, gap, items, onItemClick} = props;
    const divItems = items && items.map(item => <WeekCalendarItem {...item} key={`item-${item.id}`} onItemClick={onItemClick}/>)
    return (
        <div style={{width: width, marginRight: gap}} className='week-calendar-row-cell'>
        {props.children}
        {divItems}
        </div>
    );
};

export default WeekCalendarRowCell;