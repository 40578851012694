import React, {useEffect, useState} from 'react';
import WeekCalendarHeader from './week-calendar-header';
import WeekCalendarRow from './week-calendar-row';
import dayjs from 'dayjs';
import WeekCalendarDescriptions from './week-calendar-descriptions';
import './week-calendar.css';
import { setStyleProperty } from '../../extensions/utils';

const WeekCalendar = ({ ...props }) => {
    const {items, startDate, startTime: startMin, finishTime: finishMin, compact, onItemClick, dayPos, setDayPos } = props;
    // const [dayPos, setDayPos] = useState(dayjs().diff(startDate, 'day'));
    const divRows = [];

    useEffect(() => {
        setStyleProperty('--block-content-backround-img', ``);
    }, []);

    for (let h = startMin/60; h < finishMin/60; h++){
        const localItems = items.filter(item => item.startMin >= h * 60 && item.startMin < (h + 1) * 60);
        if (!compact || localItems.length > 0)
        {
            divRows.push(<WeekCalendarRow index={h - startMin/60} caption={`${h}:00`}
                items={localItems}
                startDate={startDate} 
                key={`row-${h}`}
                compact={compact}
                dayPos={dayPos}
                onItemClick={onItemClick}
                />)
        }
    }
    const divEmpty = compact && items.find(item => dayjs(item.date).startOf('day').isSame(startDate.add(dayPos, 'd').startOf('day'))) == null ? <div>Групп нет...</div> : null;    
    const divHeader = <WeekCalendarHeader startDate={startDate} compact={compact} dayPos={dayPos} setDayPos={setDayPos}/>;
    const products = items.reduce((res, cur) => 
        {
            const cur2 = { name: cur.productName, description: cur.productDescription};
            return res.find((find) => JSON.stringify(find) === JSON.stringify(cur2)) ? res : [...res, cur2];
        }, []
    )

    return (
        <div className='week-calendar'>
        {divHeader}
        {divRows}
        {divEmpty}
        {!compact && <WeekCalendarDescriptions items={products}/>}
        </div>
    );
};

export default WeekCalendar;