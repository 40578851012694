import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import WeekCalendar from '../week-calendar';
import {Col, message, Row, Button, Select} from 'antd';
import { serverFetch } from '../../server';
import { useWindowWidth } from '@react-hook/window-size';
import './calendar.css';
import { CalendarDescription } from './calendar-descr';

const emptyItems = [];

const Calendar = () => {
    dayjs.locale('ru');
    const user = useSelector((s) => s.user);
    const company = useSelector((s) => s.company);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState(emptyItems);
    const [startDate, setStartDate] = useState(
        dayjs(moment()
        .utcOffset(0, true)
        .set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
        .isoWeekday(1).utcOffset(0, true)));
    const [dayPos, setDayPos] = useState(dayjs().diff(startDate, 'day'));
//    const [startDate, setStartDate] = useState(dayjs(moment().utcOffset(0, true).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).isoWeekday(1).utcOffset(0, true)));
    const [rooms, setRooms] = useState([]);
    const [sotrs, setSotrs] = useState([]);
    const [selectedRoomId, setSelectedRoomId] = useState();
    const [selectedSotrId, setSelectedSotrId] = useState();
    const [selectedUsl, setSelectedUsl] = useState();
    const onlyWidth = useWindowWidth();
    const getItems = () => {
        let queryParams = {
            companyId: company.id,
            start: startDate,
            finish: startDate.add(6, 'day')
        };
        serverFetch('Room/seanses', { method: 'GET', queryParams: queryParams }, user)
        .then((data) => {
            setItems(data);
            const roomIds = {};
            const roomIdName = [];
            data.forEach(element => {
                if (!roomIds[element.roomId]){
                    roomIds[element.roomId] = true;
                    roomIdName.push({id: element.roomId, name: element.roomName});
                }
            });
            setRooms(roomIdName);
            const sotrIds = {};
            const sotrIdName = [];
            data.forEach(element => {
                if (!sotrIds[element.sotrId]){
                    sotrIds[element.sotrId] = true;
                    sotrIdName.push({id: element.sotrId, name: element.sotrName});
                }
            });
            setSotrs(sotrIdName);
        })
        .catch((e) => {
            message.error('Ошибка получения сеансов');
            setLoading(false);
        })
    }
    useEffect(() => getItems(), [company, startDate]);
    const onSetToday = () => {
        const newStartDate = dayjs(moment().utcOffset(0, true).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).isoWeekday(1).utcOffset(0, true));
        setStartDate(newStartDate);
        setDayPos(dayjs().diff(newStartDate, 'day'));
    }
    const compact = onlyWidth < 745;

    const filteredItems = items.filter(item => {
        if (selectedRoomId && rooms && rooms.length > 0 && selectedRoomId !== item.roomId) return false;
        if (selectedSotrId && sotrs && sotrs.length > 0 && selectedSotrId !== item.sotrId) return false;
        return true;
    });

    const roomOptions = rooms.map(i => ({label: i.name, value: i.id}));
    const sotrOptions = sotrs.map(i => ({label: i.name, value: i.id}));
    //console.log(selectedUsl);
    return <Col span={24}>
        {selectedUsl && <CalendarDescription onClose={() => setSelectedUsl(null)} name={selectedUsl.productName} description={selectedUsl.productDescription}/>}
        {compact ? <Row style={{margin: "4px"}}><Button onClick={onSetToday}>Сегодня</Button></Row> : null}
        <Row className='calendar-navigation'>
            {compact ? null : <Button onClick={onSetToday}>Сегодня</Button>}
            <Button shape="circle" icon={<LeftOutlined />} onClick={() => setStartDate(startDate.add(-7, 'd'))}/>
            <div>{startDate.format('DD MMMM')} - {startDate.add(6,'day').format('DD MMMM')}</div>
            <Button shape="circle" icon={<RightOutlined />} onClick={() => setStartDate(startDate.add(7, 'd'))}/>
            {rooms && rooms.length > 0 && <Select placeholder="Зал" options={roomOptions} style={{width: 300}} onChange={(e) => setSelectedRoomId(e)} onClear={() => setSelectedRoomId()} allowClear/>}
            {sotrs && sotrs.length > 0 && <Select placeholder="Тренер" options={sotrOptions} style={{width: 300}} onChange={(e) => setSelectedSotrId(e)} onClear={() => setSelectedSotrId()} allowClear/>}
        </Row>
        <WeekCalendar items={filteredItems} startDate={startDate} 
            startTime={company && company.startMin ? company.startMin : 420} 
            finishTime={company && company.finishMin ? company.finishMin : 1200}
            compact={compact} onItemClick={(id) => setSelectedUsl(items.find(i => i.id == id))}
            dayPos={dayPos} setDayPos={setDayPos}
        />
    </Col>;
};

export default Calendar;