import { Button, Checkbox, Collapse, Modal, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { paymentSystem, productType } from "../../extensions/helper";
import { useWindowWidth } from "@react-hook/window-size";
import PaymentPageSdk from '@raiffeisen-ecom/payment-sdk';
import { serverFetch } from "../../server";
const { Panel } = Collapse;

const PayItem = ({...props}) => {
    const {onCancel, item} = props;
    const user = useSelector((s) => s.user);
    const [cond1, setCond1] = useState(true);
    const [cond2, setCond2] = useState(true);
    const [cond3, setCond3] = useState(true);
    const [condF, setCondF] = useState(true);
    //const [autopay, setAutopay] = useState(false);
    const company = useSelector((s) => s.company);
    const [loading, setLoading] = useState(false);
    const onlyWidth = useWindowWidth();

    const DisablePayment = !company || !company.paySbrf || !item || (!item.totalPrice && !item.debet) || !cond1 || !cond2 || !condF 
        || (!cond3 && item.autopay)
        || (item.productType == productType.Certificate && !item.receiverEmail)
        || loading;


    //console.log(item);
    let totalPrice = item.debet;
    const commission = Math.round(company && company.prcCommission > 0 ? totalPrice*company.prcCommission : 0)/100;
    totalPrice = totalPrice + commission;
    // const sale = {
    //     productId: item.productId,
    //     qty: 1,
    //     price: item.debet,
    //     totalPrice, 
    //     commission,
    // };

    const divCommission = commission == 0 ? null : <>
        <Row>Комиссия {commission}</Row>
        <Row>ИТОГО {totalPrice}</Row>
    </>

    const onPay = (system) => {
        const sale = {
            companyId: company.id,
            productId: 0, //item.idVidCard,
            productExternalId: `${item.idVidCard}`,
            price: item.debet,
            qty: 1,
            commission,
            totalPrice,
            autopay: item.autopay,
            externalId: item.idAbon,
        };
        setLoading(true);
        serverFetch(`sale`, { method: 'POST', bodyData: sale }, user)
            .then((data) => {
                switch (system) {
                    case paymentSystem.Raif:
                        onPayRaif(data);
                        break;
                    case paymentSystem.Sbrf:
                        onPaySbrf(data);
                        break;
                    case paymentSystem.Credit:
                        onPayCredit(data);
                        break;  
                    case paymentSystem.TinkoffCard:
                        onPayTinkoffCard(data);
                        break;
                    default:
                        break;
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                e.UserMessage && message.error(e.UserMessage);
            });
    };

    const onPayRaif = (sale) => {
        const paymentRaif = new PaymentPageSdk('MA0000838718');
        onCancel();

        paymentRaif
            .openPopup({
                orderId: sale.number,
                amount: sale.totalPrice,
                comment: sale.productName,
                paymentMethod: 'ONLY_SBP',
            })
            .then(function () {
                message.success('Оплата успешно прошла');
            });
    };

    const onPaySbrf = async (sale) => {
        const response = await serverFetch(`sbrf`, { method: 'POST', bodyData: sale }, user);
        window.location.href = response;
        onCancel();
    };

    const onPayCredit = async (sale) => {
        const response = await serverFetch(`credit`, { method: 'POST', bodyData: sale }, user);
        window.location.href = response;
        onCancel();
    };

    const onPayTinkoffCard = async (sale) => {
        const response = await serverFetch(`tinkoff`, { method: 'POST', bodyData: sale }, user);
        window.location.href = response;
        onCancel();
    };

    const offerAutopay = company && company.offerAutopay ? company.offerAutopay : process.env.REACT_APP_OFFER_AUTOPAY;
    const offerPayment = company && company.offerPayment ? company.offerPayment : process.env.REACT_APP_OFFER_PAYMENT;
    const divAutopayCondition = item && item.autopay ? <Panel header="Условия сервиса “Автоплатеж”" key="3">
        Условия пользования сервисом «Автоплатеж» в соответствии со ст.435 ГК РФ являются офертой, адресованной Пользователю. Пользователь, как держатель банковской карты, 
        производя оплату соглашается с <a href={offerAutopay} target='_blank'>Условиями</a>
    </Panel> : null
    const divAutopay = item && item.autopay ? 
        <Checkbox onChange={(e) => setCond3(e.target.checked)} defaultChecked={cond3}>Я согласен с сохранением данных карты и условиями сервиса "Автоплатеж"</Checkbox> : null

    console.log(item);

    return<Modal title={item.name} forceRender open={true} onCancel={onCancel} closable={false} footer={null}>
        <div className='catalog-item-price' style={{ marginTop: 16, marginBottom: 16 }}>
                {item.debet}
            </div>
            {/* <div>{item && item.description}</div> */}
            {divCommission}

        <div className='catalog-space'></div>
        <Collapse size="small" accordion>
            <Panel header="Условия платежа" key="1">
                Соглашаясь с <a href={offerPayment} target='_blank'>Условиями</a> платежа, вы соглашаетесь с составом и стоимостью заказа, а также с суммой комиссии, если она указана в заказе. Пожалуйста, внимательно ознакомьтесь с наименованием состава заказа, стоимостью заказа и описанием предоставления заказа.
            </Panel>
            <Panel header="Условия аннуляции заказа" key="2">
                Для аннуляции оплаченного заказа вы можете обратиться за возвратом суммы оплаты на вашу банковскую карту, предварительно обратившись с письмом на e-mail Компании Проффит: inbox@fitnessoft.ru Аннуляция заказа и возврат оплаты возможны до начала использования заказа. В случае своевременного аннулирования заказа Компания Проффит имеет право взимать с клиента сервисный сбор — за услуги аннулирования оформленного и оплаченного заказа. Сервисный сбор включается в стоимость заказа, и в случае аннулирования заказа возврату не подлежит. Размер сервисного сбора не может превышать 10% от оплаченной клиентом суммы. При аннуляции заказа до момента использования заказа денежные средства будут возвращены на банковскую карту, при помощи которой была произведена оплата услуги, в течение 20 рабочих дней с момента получения Компанией Проффит уведомления об аннулировании заказа. В случае аннуляции заказа после начала использования заказа для возврата денежных средств вам необходимо обратиться непосредственно по месту использования заказа.
            </Panel>
            {divAutopayCondition}
        </Collapse>
        <div><Checkbox onChange={(e) => setCond1(e.target.checked)} defaultChecked={cond1}>Я согласен с условиями платежа</Checkbox></div>
        <div><Checkbox onChange={(e) => setCond2(e.target.checked)} defaultChecked={cond2}>Я согласен с условиями аннуляции заявки</Checkbox></div>
        {divAutopay}
        <div className='btn-container'>
            <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={onCancel}>Назад</Button>
            {company && company.paySbrf && <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={() => onPay(paymentSystem.TinkoffCard)} 
                disabled = {DisablePayment}>
                {onlyWidth < 700 ? 'Карта' : 'Оплатить картой'}
            </Button>}
            {company && company.payRaif && <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={() => onPay(paymentSystem.Raif)} 
                disabled = {DisablePayment || item.autopay}>
                {onlyWidth < 700 ? 'СБП' : 'Оплатить СБП'}
            </Button>}
            {company && company.payCredit && <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={() => onPay(paymentSystem.Credit)} 
                disabled = {DisablePayment || item.autopay || item.debet < 3000}>
                {onlyWidth < 700 ? 'Кредит' : 'Кредит'}
            </Button>}
        </div>
    </Modal>
}

export default PayItem