import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';

import App from './App';
import { CookiesProvider } from 'react-cookie';
import configureStore from './redux/store';

import HttpsRedirect from 'react-https-redirect';

import './index.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const { store, persistor } = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={baseUrl}>
                <CookiesProvider>
                    <ConfigProvider
                        theme={{
                            token: {
                                //fontFamily: 'PT Sans',
                                fontSize: 16,
                                Button: {
                                    fontSize: 16,
                                },
                            },
                        }}
                    >
                        <HttpsRedirect>
                        <App />
                        </HttpsRedirect>
                    </ConfigProvider>
                </CookiesProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    rootElement
);
