import React from 'react';
import dayjs from 'dayjs';
import './week-calendar-row.css';
import WeekCalendarRowCell from './week-calendar-row-cell';

const WeekCalendarRow = ({ ...props }) => {
    const sCellWidth = 100;
    const gap = 4;
    const {index, caption, items, startDate, compact, dayPos, onItemClick} = props;
    const rCellWidth = compact ? "100%" : 200;
    const divCells = [];
    for (let i = 0; i < 7; i++) 
        if (!compact || i == dayPos)
            divCells.push(<WeekCalendarRowCell width={rCellWidth} gap={gap}  key={`cr-${i}`} 
                items={items.filter(item => dayjs(item.date).startOf('day').isSame(startDate.add(i, 'd').startOf('day')) )} 
                onItemClick={onItemClick}
            />);
    const div0 = compact ? null : <WeekCalendarRowCell width={sCellWidth} gap={gap} key={`cs-${index}`}>{caption}</WeekCalendarRowCell>;

    return (
        <div className={compact ? "" : index % 2 == 0 ? 'week-calendar-row-even' : 'week-calendar-row-odd'}>
        {div0}
        {divCells}
        </div>
    );
};

export default WeekCalendarRow;