import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { Table, Typography, ConfigProvider, Empty, message, Button, Col, Card, Row, Alert } from 'antd';
import moment from 'moment';
import { setStyleProperty } from '../../extensions/utils';
import { serverFetch } from '../../server';
import './purchases.css';
import Auth from '../auth/auth';

const Purchases = () => {
    const { Text } = Typography;
    const company = useSelector((s) => s.company);
    const user = useSelector((s) => s.user);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const onlyWidth = useWindowWidth();
    const [client, setClient] = useState();

    const getClient = () => serverFetch('account/Info', { method: 'GET'}, user)
        .then((data) => setClient(data))
        .catch((e) => message.error('Ошибка получения информации о клиенте'));

    useEffect(() => {
        setStyleProperty('--block-content-backround-img', ``);
        //getData();
        //getClient();
    }, []);

    useEffect(() => {
        user && getData();
        user && getClient();
    }, [user]);

    const getData = () => {
        setLoading(true);

        let queryParams = {
            companyId: company.id,
            useClientId: true,
        };

        serverFetch('sale', { method: 'GET', queryParams: queryParams }, user)
            .then((data) => {
                setItems(data);
                setLoading(false);
            })
            .catch((e) => {
                message.error('Ошибка получения покупок');
                setLoading(false);
            });
    };

    const onSelectChange = (selectedRowKeys) => {};

    const getColumns = () => {
        return [
            {
                title: '#',
                dataIndex: 'id',
                width: 120,
                align: 'center',
            },
            {
                title: 'Дата',
                dataIndex: 'createdOn',
                render: (_, record) => {
                    return <>{moment(record.createdOn).format('DD.MM.YYYY')} </>;
                },
                width: 120,
                align: 'center',
            },
            {
                title: 'Услуга',
                dataIndex: 'productName',
                width: 460,
                align: 'left',
            },
            {
                title: 'Стоимость',
                dataIndex: 'price',
                width: 200,
                align: 'center',
            },
            {
                title: 'Зал',
                dataIndex: 'roomName',
                width: 250,
                align: 'left',
            },
            {
                title: 'Визит',
                dataIndex: 'seansStart',
                align: 'center',
                width: 200,
                render: (_, record) => {
                    return <>{record.seansStart && moment(record.seansStart).format('DD.MM')} {record.seansStart && moment(record.seansStart).format('HH:mm')}-{record.seansFinish && moment(record.seansFinish).format('HH:mm')} </>;
                },                
            },
            {
                title: 'Клиентов',
                dataIndex: 'qtyClients',
                align: 'center',
            },
            
            {
                title: 'Чек',
                dataIndex: 'fiscalReceiptStatus',
                width: 70,
                align: 'center',
                render: (_, record) => {
                    return record.fiscalReceiptStatus == 'COMPLETED' && record.fiscalReceiptUrl
                    ? <Button type="link" target="_blank" href={record.fiscalReceiptUrl}>Чек</Button> : 
                    <>{null}</>
                },
            },
        ];
    };

    const renderTable = () => {
        let columns = getColumns();

        return (
            <ConfigProvider renderEmpty={() => <Empty description='Список покупок пуст' />}>
                <Table
                    style={{ width: 1200, margin: 'auto' }}
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={items}
                    loading={loading}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onSelectChange([record.id]);
                            },
                        };
                    }}
                    pagination={false}
                    summary={(pageData) => {
                        let total = 0;

                        pageData.forEach(({ price }) => {
                            total += price;
                        });
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell align='center'>
                                        <Text strong>{total}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                />
            </ConfigProvider>
        );
    };

    const renderItems = () => {
        return (
            <>
                {items &&
                    items.map((i) => {
                        const divRoom = i.roomName ? <>
                        <div>{i.roomName}</div>
                        <div className='purchase-card-price'>Визит {i.seansStart && moment(i.seansStart).format('DD.MM.YYYY')} {i.seansStart && moment(i.seansStart).format('HH:mm')} - {i.seansFinish && moment(i.seansFinish).format('HH:mm')}</div>
                        </> : null;
                        const divCheck = i.fiscalReceiptUrl ? 
                            <div className='purchase-card-price'>
                                <Button href={i.fiscalReceiptUrl} target="_blank">Показать чек</Button>
                            </div> : null;
                        return (
                            <Col key={i.id} span={24}>
                                <Card
                                    className='flexible-card'
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                >
                                    <Row>
                                    <div className='purchase-card-data'>Продажа # {i.id}</div>
                                    <div className='purchase-card-data'>{moment(i.createdOn).format('DD.MM.YYYY')} </div>
                                    <div className='purchase-card-price'>{i.totalPrice}₽</div>
                                    </Row>
                                    <div className='purchase-card-price'>{i.productName}</div>
                                    {divRoom}
                                    {divCheck}
                                </Card>
                            </Col>
                        );
                    })}
            </>
        );
    };

    return <>
        {client && !(client.email) 
            && <Alert type="warning" message="Укажите адрес почты в профиле пользователя 'Мои данные'" showIcon />}
        {onlyWidth > 744 ? renderTable() : renderItems() }
        {!user && <Auth open={true} />}
    </>;
};

export default Purchases;
