import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useParams } from 'react-router';
import { Card, Modal, Button, Row, Col, message, Tag, FloatButton, Image, Collapse, Checkbox } from 'antd';
import PaymentPageSdk from '@raiffeisen-ecom/payment-sdk';
import { useWindowWidth } from '@react-hook/window-size';

import { productType, paymentSystem } from '../../extensions/helper';
import { setStyleProperty, cnvToMultiline } from '../../extensions/utils';

import Auth from '../auth/auth.js';
import Subscription from './subscription';
import Certificate from './certificate';
import Bookign from './booking';

import { serverFetch } from '../../server';

import './catalog.css';
const icon = require('../../assets/sport_logo.png');
const { Panel } = Collapse;

const emptyGroups = [{ id: 0, name: 'Загрузка ...', description: '' }];
const emptyItems = [
    { id: 0, name: '', description: '', catalogGroupId: 0 }, 
    { id: 1, name: '', description: '', catalogGroupId: 0 }, 
    { id: 2, name: '', description: '', catalogGroupId: 0 },
    { id: 3, name: '', description: '', catalogGroupId: 0 }
];

const Catalog = () => {
    const user = useSelector((s) => s.user);
    const company = useSelector((s) => s.company);
    const { id } = useParams();
    const onlyWidth = useWindowWidth();
    const [groups, setGroups] = useState(emptyGroups);
    const [items, setItems] = useState(emptyItems);
    const [currentItem, setCurrentItem] = useState();
    const [currentRents, setCurrentRents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    const [showSignin, setShowSignin] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [cond1, setCond1] = useState(false);
    const [cond2, setCond2] = useState(false);
    const [cond3, setCond3] = useState(false);
    const [condF, setCondF] = useState(true);
    const [autopay, setAutopay] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [currentGroupId, setCurrentGroupId] = useState();

    useEffect(() => {
        if (company) {
            company.catalogBackgroundImage && setStyleProperty('--block-content-backround-img', `url("${company.catalogBackgroundImage}")`);
            let cleanup = false;
            const fetchData = async () => {
                setLoading(true);
                let queryParams = {
                    companyId: company.id,
                    isActive: true,
                    noImage: true,
                };
                let promises = [
                    await serverFetch('catalog/groups', { method: 'GET', queryParams: queryParams }, user)
                        .then((data) => {
                            return data;
                        })
                        .catch((e) => {
                            message.error('Ошибка получения групп каталога');
                            setLoading(false);
                        }),

                    await serverFetch(`catalog/items`, { method: 'GET', queryParams: queryParams }, user)
                        .then((data) => {
                            return data;
                        })
                        .catch((e) => {
                            message.error('Ошибка получения элементов каталога');
                            setLoading(false);
                        }),
                ];
                Promise.all([promises]).then((result) => {
                    if (cleanup) return;
                    setGroups(result[0][0] || []);
                    setItems(result[0][1] || []);
                    setLoading(false);
                    setLoaded(true);
                });
            };
            fetchData();
            return () => (cleanup = true);
        }
    }, [company]);

    useEffect(() => {
        if (user && currentItem) {
            setOpenItem(true);
        }
    }, [currentItem, user]);

    useEffect(() => {
        if (loaded && id && groups && !scroll) {
            const element = document.getElementById(id);
            if (element) {
                // console.log(element);
                setScroll(true);
                element.scrollIntoView({ behavior: 'smooth', block: 'start' }); // , inline: 'start'
                window.history.replaceState(null, null, "/catalog");
            }
        }
    }, [loaded]);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
    
        return () => {
          document.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // Определяем, находится ли элемент в области видимости
        const elements = document.querySelectorAll('.group'); // замените '.track-scroll' на селектор ваших элементов
        let currentElement = null;

        elements.forEach((element) => {
            const elementTop = element.offsetTop;
            const elementHeight = element.clientHeight;
            if (scrollPosition >= elementTop && scrollPosition < elementTop + elementHeight) {
            currentElement = element.id;
            }
        });
        if (currentElement) {
            // currentElement - id группы
            setCurrentGroupId(currentElement);
        }
    };

    const onPay = (system) => {
        //console.log("-> onPay", system, currentRents);
        const sale = { companyId: company.id, ...currentItem, externalId: null };
        if (currentRents.length == 0){
            serverFetch(`sale`, { method: 'POST', bodyData: sale }, user)
                .then((data) => {
                    setCurrentItem();
                    switch (system) {
                        case paymentSystem.Raif:
                            onPayRaif(data);
                            break;
                        case paymentSystem.Sbrf:
                            onPaySbrf(data);
                            break;
                        case paymentSystem.Credit:
                            onPayCredit(data);
                            break;
                        case paymentSystem.TinkoffCard:
                            //console.log("->onPayTinkoffCard");
                            onPayTinkoffCard(data);
                            break;  
                        default:
                            break;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    e.UserMessage && message.error(e.UserMessage);
                });
        }
        else {
            //console.log(sale);
            let sales = [sale];
            currentRents.forEach(item => sales.push({...sale, catalogId: 0, productId: item.productId, rentSize: item.size, price: 0, totalPrice: 0, qtyClients: 1, externalId: null}));
            serverFetch(`sale/basket`, { method: 'POST', bodyData: sales }, user)
                .then((data) => {
                    console.log(data);
                    setCurrentItem();
                    switch (system) {
                        case paymentSystem.Raif:
                            onPayRaif(data);
                            break;
                        case paymentSystem.Sbrf:
                            onPaySbrf(data);
                            break;
                        case paymentSystem.Credit:
                            onPayCredit(data);
                            break;   
                        case paymentSystem.TinkoffCard:
                            //console.log("->onPayTinkoffCard");
                            onPayTinkoffCard(data);
                            break;                         
                        default:
                            break;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    e.UserMessage && message.error(e.UserMessage);
                });
        }
    };

    const onPayRaif = (sale) => {
        const paymentRaif = new PaymentPageSdk('MA0000838718');
        setOpenItem(false);

        paymentRaif
            .openPopup({
                orderId: sale.number,
                amount: sale.totalPrice,
                comment: sale.productName,
                paymentMethod: 'ONLY_SBP',
            })
            .then(function () {
                message.success('Оплата успешно прошла');
            });
    };

    const onPaySbrf = async (sale) => {
        const response = await serverFetch(`sbrf`, { method: 'POST', bodyData: sale }, user);
        window.location.href = response;
        setOpenItem(false);
    };

    const onPayTinkoffCard = async (sale) => {
        //console.log("onPayTinkoffCard", sale);
        const response = await serverFetch(`tinkoff`, { method: 'POST', bodyData: sale }, user);
        window.location.href = response;
        setOpenItem(false);
    };

    const onPayCredit = async (sale) => {
        const response = await serverFetch(`credit`, { method: 'POST', bodyData: sale }, user);
        window.location.href = response;
        setOpenItem(false);
    };

    const onOpenItem = (item) => {
        //console.log("onOpenItem", item);
        setCurrentRents([]);
        setAutopay(item.autopay);
        setCond1(false);
        setCond2(false);
        setCond3(!item.autopay);
        setCondF(true);
        setCurrentItem({...item, catalogId: item.id} );
        if (!user) {
            setOpenItem(false);
            setShowSignin(true);
        } else {
            setOpenItem(true);
        }

        const message = { sender: 'proffit_scroll' };
        window.parent.postMessage(message, '*');
    };

    const onCancel = () => {
        setCurrentItem();
        setOpenItem(false);
    };

    const onClickHeader = id => {
        const element = document.getElementById(id);
        if (element) {
            setScroll(true);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' }); // , inline: 'start'
        }
    }

    //console.log(groups);
    const divMobileHeaders = groups && <div className='catalog-mob-header' key="mob-header-1">
        {groups
            .filter(g => items.find(i => i.catalogGroupId == g.id))
            .map(g => {
                const className2 = currentGroupId == g.id ? " catalog-mob-header-selected" : "";
                return<div className={'catalog-mob-header-item' + className2} onClick={() => onClickHeader(g.id)} key={g.id}>{g.name}</div>
            })}
    </div>
    //console.log(currentItem);
    const renderItems = () => {
        return (
            <>
                {divMobileHeaders}
                {groups &&
                    groups.map((g) => {
                        var filteredItems = items.filter((i) => i.catalogGroupId === g.id);
                        if (filteredItems.length <= 0) return false;
                        return (
                            <div key={g.id} id={g.id} className='group'>
                                <Row><div className='catalog-group-title'>{g.name}</div></Row>
                                <Row gutter={[16, 16]} style={{ marginBottom: 32 }}>
                                    {filteredItems.map((i) => {
                                        const tagBestSeller = i.isBestSeller ? <Tag color={'#ff9600'}>Хит продаж</Tag> : null;
                                        const tagBestPrice  = i.isBestPrice ? <Tag color={'#029911'}>Новинка</Tag> : null;
                                        const tagIsAction   = i.isAction ? <Tag color={'#ff0000'}>Акция</Tag> : null;
                                        const tagCredit = company && company.payCredit && !i.autopay && i.price >= 3000 ? <Tag color='#FEDD2C' style={{color: 'black'}}>В кредит от {Math.round(i.price/20)} ₽ в месяц</Tag> : null;
                                        const divDiscountPrice = i.productPrice && i.qty == 1 && i.productPrice > i.price ? <div className='catalog-price-discount'>{i.productPrice.toLocaleString()} ₽</div> : null;
                                        const divPrice = i.price && i.price != 0 ? <div className='catalog-item-price'>{i.price.toLocaleString()}</div> : null;
                                        return (
                                            <Col key={i.id} span={onlyWidth >= 1400 ? 6 : onlyWidth >= 744 ? 12 : 24}>
                                                <Card
                                                    className='flexible-card'
                                                    style={{ display: 'flex', flexDirection: 'column' }}
                                                    title={i.name}
                                                    actions={loading ? null : [
                                                        <Button className='catalog-item-btn' onClick={() => onOpenItem(i)} size='middle'>Купить</Button>,
                                                    ]}
                                                    loading={loading}
                                                >
                                                    <Row className='catalog-row-price'>
                                                        <Image height={40} width={77}  src={(company && company.logoImage) || icon} preview={false} />
                                                        <div className='catalog-price'>{divDiscountPrice}{divPrice}</div>
                                                    </Row>
                                                    <div className='catalog-item-description'>{cnvToMultiline(i.description)}</div>
                                                    <div>{tagBestSeller}{tagBestPrice}{tagIsAction}{tagCredit}</div>                                                    
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        );
                    })}
            </>
        );
    };

    const onItemChange = (data) => {
        const newx = Object.assign({...currentItem}, data);
        newx['qty'] = currentItem.qty;
        setCurrentItem(newx);
    }

    const modalContent = (item) => {
        switch (item.productType) {
            case productType.Subscription: {
                return <Subscription item={item} setCurrentItem={onItemChange} />;
            }
            case productType.Certificate: {
                return <Certificate item={item}  setCurrentItem={onItemChange} setCond={setCondF}/>;
            }
            case productType.Booking: {
                return <Bookign item={item} setCurrentRents={setCurrentRents} setCurrentItem={(data) => setCurrentItem(Object.assign({...currentItem}, data))} />;
            }
            default: {
                return <></>;
            }
        }
    };

    const offerAutopay = company && company.offerAutopay ? company.offerAutopay : process.env.REACT_APP_OFFER_AUTOPAY;
    const offerPayment = company && company.offerPayment ? company.offerPayment : process.env.REACT_APP_OFFER_PAYMENT;
    const divAutopayCondition = autopay ? <Panel header="Условия сервиса “Автоплатеж”" key="3">
        Условия пользования сервисом «Автоплатеж» в соответствии со ст.435 ГК РФ являются офертой, адресованной Пользователю. Пользователь, как держатель банковской карты, 
        производя оплату соглашается с <a href={offerAutopay} target='_blank'>Условиями</a>
    </Panel> : null
    const divAutopay = autopay ? 
        <Checkbox onChange={(e) => setCond3(e.target.checked)} checked={cond3}>Я согласен с сохранением данных карты и условиями сервиса "Автоплатеж"</Checkbox> : null

    const DisablePayment = !company || !company.paySbrf || !currentItem || (!currentItem.totalPrice && !currentItem.price) || !cond1 || !cond2 || !condF || (!cond3 && autopay)
        || (currentItem.productType == productType.Certificate && !currentItem.receiverEmail);

    //console.log(currentRents);
    const divExtraInfo = <a href={offerPayment} target='_blank'>условиями</a>
    //console.log(company);
    //console.log(cond3);
    return (
        <>
            {renderItems()}
            {currentItem && openItem &&  (
                <Modal title={currentItem.name} forceRender open={openItem} onCancel={() => onCancel()} closable={false} footer={null}>
                    {modalContent(currentItem)}
                    <div className='catalog-space'></div>
                    <Collapse size="small" accordion>
                        <Panel header="Условия платежа" key="1">
                            Соглашаясь с {divExtraInfo} платежа, вы соглашаетесь с составом и стоимостью заказа, а также с суммой комиссии, 
                            если она указана в заказе. Пожалуйста, внимательно ознакомьтесь с наименованием состава заказа, 
                            стоимостью заказа и описанием предоставления заказа.
                        </Panel>
                        <Panel header="Условия аннуляции заказа" key="2">
                            Для аннуляции оплаченного заказа вы можете обратиться за возвратом суммы оплаты на вашу банковскую карту, предварительно обратившись с письмом на e-mail Компании Проффит: inbox@fitnessoft.ru Аннуляция заказа и возврат оплаты возможны до начала использования заказа. В случае своевременного аннулирования заказа Компания Проффит имеет право взимать с клиента сервисный сбор — за услуги аннулирования оформленного и оплаченного заказа. Сервисный сбор включается в стоимость заказа, и в случае аннулирования заказа возврату не подлежит. Размер сервисного сбора не может превышать 10% от оплаченной клиентом суммы. При аннуляции заказа до момента использования заказа денежные средства будут возвращены на банковскую карту, при помощи которой была произведена оплата услуги, в течение 20 рабочих дней с момента получения Компанией Проффит уведомления об аннулировании заказа. В случае аннуляции заказа после начала использования заказа для возврата денежных средств вам необходимо обратиться непосредственно по месту использования заказа.
                        </Panel>
                        {divAutopayCondition}
                    </Collapse>
                    <div>
                        <Checkbox onChange={(e) => setCond1(e.target.checked)} defaultChecked={cond1}>Я согласен с условиями платежа</Checkbox>
                    </div>
                    <div>
                        <Checkbox onChange={(e) => setCond2(e.target.checked)} defaultChecked={cond2}>Я согласен с условиями аннуляции заявки</Checkbox>
                    </div>
                    {divAutopay}
                    <div className='btn-container'>
                        <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={() => setOpenItem(false)}>Назад</Button>
                        {company && company.paySbrf && <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={() => onPay(paymentSystem.TinkoffCard)} 
                            disabled = {DisablePayment}>
                            {onlyWidth < 700 ? 'Карта' : 'Оплатить картой'}
                        </Button>}
                        {company && company.payRaif && <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={() => onPay(paymentSystem.Raif)} 
                            disabled = {DisablePayment || autopay}>
                            {onlyWidth < 700 ? 'СБП' : 'Оплатить СБП'}
                        </Button>}
                        {company && company.payCredit && <Button className='catalog-item-pay-btn' type='default' size='middle' onClick={() => onPay(paymentSystem.Credit)} 
                            disabled = {DisablePayment || autopay || currentItem.totalPrice < 3000}>
                            {onlyWidth < 700 ? 'Кредит' : 'Кредит'}
                        </Button>}
                    </div>
                </Modal>
            )}
            {showSignin && <Auth open={true} onClose={() => setShowSignin(false)} />}
            <FloatButton.BackTop />
        </>
    );
};

export default withRouter(Catalog);
