import React, { useEffect } from 'react';

import { setStyleProperty } from '../../extensions/utils';

import './visits.css';

const Visits = () => {
    useEffect(() => {
        setStyleProperty('--block-content-backround-img', ``);
    }, []);

    return <></>;
};

export default Visits;
