export const emptyImage = `/images/empty.jpg`;

export const permission = {};

export const productType = {
    Subscription: 1,
    Certificate: 2,
    Booking: 3
};

export const qtyClients = [1,2,3,4,5,6,7,8,9,10];

export const paymentSystem = {
    Sbrf: 1,
    Raif: 2,
    Credit: 3,
    TinkoffCard: 4,
};

export function hasPermission(permissions, permission) {
    if (!permissions) return false;

    let value = permissions[permission - 1];
    return value === 1;
}

export const getPermissionLabel = (code) => {
    switch (code) {
        default:
            return '';
    }
};
