import React from 'react';
import './week-calendar-header-cell.css';

const WeekCalendarHeaderCell = ({ ...props }) => {
    const {width, gap, compact, selected, index, setDayPos} = props;
    return (
        <div style={{width: width, marginRight: gap}} 
            className={"week-calendar-header-cell" 
                + (compact ? " week-calendar-header-cell-compact" : "")
                + (selected ? " week-calendar-header-cell-selected" : "") }
            onClick={() => setDayPos(index)}
        >
        {props.children}
        </div>
    );
};

export default WeekCalendarHeaderCell;