import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router';
import { Card, Image, Carousel, Col, Row, Button, message } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useWindowWidth } from '@react-hook/window-size';
import { setCurrentNavMenu } from '../../redux/actions';
import { setStyleProperty } from '../../extensions/utils';
import { emptyImage } from '../../extensions/helper';
import { serverFetch } from '../../server';
import './home.css';
import DOMPurify from 'dompurify';

const Home = () => {
    const { Meta } = Card;
    const d = useDispatch();
    const emptyGroups = [{ id: 0, name: '', description: '' }];
    const history = useHistory();
    const user = useSelector((s) => s.user);
    const company = useSelector((s) => s.company);
    const onlyWidth = useWindowWidth();
    const [groups, setGroups] = useState(emptyGroups);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setStyleProperty('--block-content-backround-img', ``);
    }, []);

    useEffect(() => {
        if (company && company.id > 0) {
            setLoading(true);
            let queryParams = {
                companyId: company.id,
                isActive: true,
            };
            serverFetch('catalog/groups', { method: 'GET', queryParams: queryParams }, user)
                .then((data) => {
                    setGroups(data);
                    setLoading(false);
                })
                .catch((e) => {
                    message.error('Ошибка получения групп каталога');
                    setLoading(false);
                });
        }
    }, [company,user]);



    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <Button {...props} aria-hidden='true' shape='circle' icon={<LeftCircleOutlined />} />
    );
    const SlickArrowRight = ({ currentSlide, slideCount, slidesToShow, ...props }) => {
        return <Button {...props} aria-hidden='true' type='button' shape='circle' icon={<RightCircleOutlined />} />;
    };

    const renderGroups = () => {
        return (
            groups && (
                <Carousel
                    arrows={true}
                    infinite={true}
                    slidesToShow={Math.min(onlyWidth >= 1400 ? 4 : onlyWidth >= 744 ? 2 : 1, groups.length)}
                    slidesToScroll={1}
                    //prevArrow={<SlickArrowLeft />}
                    //nextArrow={<SlickArrowRight />}
                    dots={false}
                >
                    {groups.map((g) => {
                        return (
                            <Card
                                className='flexible-card card-shadow'
                                key={g.id}
                                title={<div className='home-group-title'>{g.name}</div>}
                                cover={<img alt={g.name} src={g.image || emptyImage} style={{ height: 188 }} />}
                                onClick={() => {
                                    d(setCurrentNavMenu('catalog'));
                                    history.push(`/catalog/${g.id}`);
                                }}
                                hoverable={true}
                                loading={loading}
                            >
                                <Meta description={g.description} />
                            </Card>
                        );
                    })}
                </Carousel>
            )
        );
    };

    const cardSpan = onlyWidth >= 1400 ? Math.min(4, groups.length) * 6 : onlyWidth >= 744 ? Math.min(2, groups.length) * 12 : 24;
    const cardOffset = (24 - cardSpan) / 2;

    return (
        <>
            <Row>
                <Col span={onlyWidth >= 1400 ? 16 : 24} offset={onlyWidth >= 1400 ? 4 : 0} className='home-description'>
                    {company && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(company.description, {USE_PROFILES: { html: true }, FORBID_TAGS: ['a', 'object','iframe']}) }} />}
                </Col>
            </Row>
            <Row>
                <Col span={cardSpan} offset={cardOffset} className='home-groups'>
                    {renderGroups()}
                </Col>
            </Row>
            <Row>
                {company && company.loadingPerDayImage && (
                    <Col span={24} style={{ textAlign: 'center', marginTop: 32 }}>
                        <p>Посещаемость комплекса</p>
                        <Image src={company.loadingPerDayImage} preview={false} />
                    </Col>
                )}
            </Row>
        </>
    );
};

export default withRouter(Home);