import { serverFetch } from '../server';

export const openwindow = (url)  => {
    const width = 400;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    window.open(url,'_blank', `width=${width},height=${height},left=${left},top=${top},noopener`);
}

export const authvk = (domain, provider, clbState)  => {
    const redirect_uri = `https://rd.sport24.cloud?redirect=https://${domain}/vk`;
    //const redirect_uri = `https://rd.sport24.cloud?redirect=http://localhost:3001/vk`;
    //const redirect_uri = "https://rd.sport24.cloud";
    serverFetch(`vk/state?redirectUrl=${redirect_uri}&backUrl=${window.location.href}`)
        .then((data) => {
            const {state, codeChallenge} = data;
            clbState(state);
            const CLIENT_ID = process.env.REACT_APP_VK_CLIENT_ID;
            const scope = "email phone"; // Список прав доступа, которые нужны приложению. Значения в списке разделяются пробелами, например: email phone.
            //const provider = "vkid"; // vkid, ok_ru, mail_ru
            const prompt = "login"; // none login consent 
            //const code_challenge = generateCodeChallenge(generateCodeVerifier);
            const code_challenge_method = 's256';
            const url = `https://id.vk.com/authorize?`
                    +`response_type=code` // Требуемый ответ. При запросе кода подтверждения следует указать значение code
                    +`&client_id=${CLIENT_ID}` // Идентификатор приложения, которое запрашивает авторизацию
                    +`&code_challenge=${codeChallenge}`
                    +`&code_challenge_method=${code_challenge_method}`
                    +`&redirect_uri=${redirect_uri}`
                    +`&state=${state}`
                    +`&scope=${scope}`
                    +`&provider=${provider}`
                    +`&prompt=${prompt}`
                    ;
            openwindow(url);
            //window.location.href = url;
        })
        .catch(e => console.log(`Ошибка ${e.messge}`));
}