import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, ConfigProvider, message, Space, Button } from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';
import { useWindowWidth } from '@react-hook/window-size';
import WebFont from 'webfontloader';
import Header from './components/header/header';
import NavMenu from './components/nav-menu/nav-menu';
import Home from './components/home/home';
import Catalog from './components/catalog/catalog';
import Purchases from './components/purchase/purchases';
import Visits from './components/visits/visits';
import Calendar from './components/calendar/calendar';
import { companyLoaded, userLoaded } from './redux/actions';
import { getIsVkSession, setStyleProperty } from './extensions/utils';
import { serverFetch } from './server';
import { Footer } from 'antd/lib/layout/layout';
import CookieConsent from "react-cookie-consent";
import History from './components/history/history';
import Article from './components/article/article';
import Vk from './components/auth/vk';
import { useLocation } from 'react-router-dom';

const App = () => {
    const { Content } = Layout;
    const d = useDispatch();
    const user = useSelector((s) => s.user);
    const company = useSelector((s) => s.company);
    const onlyWidth = useWindowWidth();
    const isFrame = window.self !== window.top;
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get(process.env.REACT_APP_VK_PARAM_NAME);
        if (paramValue) {
            sessionStorage.setItem(process.env.REACT_APP_VK_PARAM_NAME, paramValue);
          } else {
          }
    }, []);
    
    useEffect(() => {
        serverFetch(`company`, { method: 'GET' })
            .then((data) => {
                if (data) {
                    d(companyLoaded(data));
                    setStyleProperty('--default-color', data.primaryColor);
                    setStyleProperty('--primary-color', data.primaryColor);
                    setStyleProperty('--hover-color', data.primaryColor);
                    setStyleProperty('--active-color', data.primaryColor);
                    document.title = `${data.name} (${process.env.REACT_APP_VERSION})`;

                    if (window.self == window.top && company.chat) {
                        var script1 = document.createElement('script'); 
	                    script1.src = process.env.REACT_APP_CHAT;
	                    document.body.appendChild(script1);
                    }
                } else {
                    message.error('Ошибка загрузки компании');
                }
            })
            .catch((e) => {
                e.UserMessage && message.error(e.UserMessage);
            });
    }, []);

    useEffect(() => {
        if (user != null) {
            let queryParams = {
                sessionId: user.id,
            };
            serverFetch('account/checksession', { method: 'GET', queryParams: queryParams }, null)
            .then((data) => { /*console.log("Сессия проверена успешно", data)*/ })
            .catch(() => {
                d(userLoaded())
            });
        }
    }, [user]);

    const linkStyle = {cursor: 'pointer', color: 'white'};
    const offerAutopay = company && company.offerAutopay ? company.offerAutopay : process.env.REACT_APP_OFFER_AUTOPAY;
    const offerPayment = company && company.offerPayment ? company.offerPayment : process.env.REACT_APP_OFFER_PAYMENT;
    return (
        <Router>
            <ConfigProvider locale={ru_RU}>
                <Layout style={{ minHeight: '100vh' }}>
                {/* <Button onClick={onTest}>TEST</Button> */}
                    <div className='header-container'>
                        {isFrame || <Header />}
                        
                    </div>
                    <div className='header-menu'>
                        <div className='header-container'>
                            <NavMenu />
                        </div>
                    </div>
                    <Content>
                        <div className='content-container'>
                            <div className='block-content'>
                                <Switch>
                                    <Route exact path='/' component={Home} />
                                    <Route path='/home' component={Home} />
                                    <Route path='/catalog/:id?' component={Catalog} />
                                    <Route path='/purchases' component={Purchases} />
                                    <Route path='/visits' component={Visits} />
                                    <Route path='/calendar' component={Calendar} />
                                    <Route path='/history' component={History} />
                                    <Route path='/article/:name' component={Article} />
                                    <Route path='/vk' component={Vk} />
                                    <Redirect to="/" />
                                </Switch>
                            </div>
                            <div id="main-footer" style={{height: 1}}/>
                        </div>
                    </Content>
                    {(!isFrame || getIsVkSession()) ? <Footer>
                    <Space
                            size={onlyWidth >= 744 ? 'middle' : 'small'}
                            direction={onlyWidth >= 744 ? 'horizontal' : 'vertical'}
                            style={{ fontSize: 14 }}
                        >
                            <a href='https://sport24.cloud/' target='_blank'>
                                <div style={linkStyle}>(с) Виджет онлайн продаж</div>
                            </a>
                            <a href={offerPayment}><div style={linkStyle}>Описание сервиса</div></a>
                            <a href="/article/agreement"><div style={linkStyle}>Пользовательское соглашение</div></a>
                            <a href="/article/rules"><div style={linkStyle}>Условия и правила</div></a>
                            <a href={offerAutopay}><div style={linkStyle}>Условия сервиса “Автоплатеж”</div></a>
                        </Space>
                    </Footer> : null}
                    {isFrame || 
                        <CookieConsent buttonText="Принять">
                            Мы используем файлы cookie чтобы обеспечить Вам наилучшую работу на нашем веб-сайте. Продолжая использовать этот сайт, Вы даете согласие на использование файлов cookie
                        </CookieConsent>}
                </Layout>
            </ConfigProvider>
        </Router>
    );
};

export default App;
