import React from 'react';
import './week-calendar-header.css';
import WeekCalendarHeaderCell from './week-calendar-header-cell';

const WeekCalendarHeader = ({ ...props }) => {
    const sCellWidth = 100;
    const rCellWidth = 200;
    const gap = 4;
    const {startDate, compact, dayPos, setDayPos} = props;
    return (
        <div className='week-calendar-header'>
        {compact ? null : <WeekCalendarHeaderCell width={sCellWidth} gap={gap}></WeekCalendarHeaderCell>}
        <WeekCalendarHeaderCell compact={compact} selected={dayPos == 0} width={rCellWidth} gap={gap} index={0} setDayPos={setDayPos}>ПН {startDate.format('DD.MM')}</WeekCalendarHeaderCell>
        <WeekCalendarHeaderCell compact={compact} selected={dayPos == 1} width={rCellWidth} gap={gap} index={1} setDayPos={setDayPos}>ВТ {startDate.add(1, 'd').format('DD.MM')}</WeekCalendarHeaderCell>
        <WeekCalendarHeaderCell compact={compact} selected={dayPos == 2} width={rCellWidth} gap={gap} index={2} setDayPos={setDayPos}>СР {startDate.add(2, 'd').format('DD.MM')}</WeekCalendarHeaderCell>
        <WeekCalendarHeaderCell compact={compact} selected={dayPos == 3} width={rCellWidth} gap={gap} index={3} setDayPos={setDayPos}>ЧТ {startDate.add(3, 'd').format('DD.MM')}</WeekCalendarHeaderCell>
        <WeekCalendarHeaderCell compact={compact} selected={dayPos == 4} width={rCellWidth} gap={gap} index={4} setDayPos={setDayPos}>ПТ {startDate.add(4, 'd').format('DD.MM')}</WeekCalendarHeaderCell>
        <WeekCalendarHeaderCell compact={compact} selected={dayPos == 5} width={rCellWidth} gap={gap} index={5} setDayPos={setDayPos}>СБ {startDate.add(5, 'd').format('DD.MM')}</WeekCalendarHeaderCell>
        <WeekCalendarHeaderCell compact={compact} selected={dayPos == 6} width={rCellWidth} gap={gap} index={6} setDayPos={setDayPos}>ВС {startDate.add(6, 'd').format('DD.MM')}</WeekCalendarHeaderCell>
        </div>
    );
};

export default WeekCalendarHeader;