import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Layout, Image } from 'antd';
import { useWindowWidth } from '@react-hook/window-size';

import './header.css';

const icon = require('../../assets/sport_logo.png');

const Header = () => {
    const { Header } = Layout;

    const company = useSelector((s) => s.company);

    const onlyWidth = useWindowWidth();

    const headerMain = <div className='header-main'>
        <div className='header-title'>{company && company.name}</div>
        <div className='header-prompt'>{company && company.address}</div>
    </div>
    const headerLogo = <div className='header-logo'>
        <Link to='/home'>
            <Image width={185} height={96} src={(company && company.logoImage) || icon} preview={false} style={{marginTop: -5}}/>
        </Link>
    </div>

    /*useEffect(() => {
        console.log("header");
    }, []);*/

    return (
        <>
            {onlyWidth >= 1400 && 
                <Header className='header'>
                    {headerLogo}
                    {headerMain}
                </Header>
            }
            <div>
            {onlyWidth < 1400 && headerLogo}
            {onlyWidth < 1400 && headerMain}
            </div>
        </>
    );
};

export default Header;
