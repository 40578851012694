import { Button, Modal } from "antd";
import React from "react";
import './calendar-descr.css';

export const CalendarDescription = ({...props}) => {
  const {name, description, onClose} = props;

  return<Modal
    forceRender
    open
    onCancel={onClose}
    title={name}
    closeOnOutsideClick
    closable={false}
    onOk={onClose}
    footer={null}
  >
    <div>{description}</div>
    <div className="cal-desc-buttons"><Button onClick={onClose} type="default">OK</Button></div>
  </Modal>
}