import { Table } from "antd";
import React from "react";
import './history-services.css';
import { useWindowWidth } from '@react-hook/window-size';
import dayjs from "dayjs";

const HistoryServices = ({...props}) => {
    const {items, visits, title} = props;
    const onlyWidth = useWindowWidth();
    const getColumns = () => { 
        let cols = [];
        cols.push({ title: 'Наименование', dataIndex: 'name', align: 'left', key: 'name' });
        if (onlyWidth > 744)
        {
            cols.push({ title: 'Единица', dataIndex: 'unit', align: 'left', key: 'unit' });
            cols.push({ title: 'Куплено', dataIndex: 'qtyExp', align: 'left', key: 'qtyExp' });
            cols.push({ title: 'Использовано', dataIndex: 'qtyUsed', align: 'left', key: 'qtyUsed' });
        }
        else
        {
            cols.push({ title: 'Ед.', dataIndex: 'unit', align: 'left', key: 'unit' });
        }

        cols.push({ title: 'Остаток', dataIndex: 'qtyRest', align: 'left', key: 'qtyRest' });
        return cols;
    }

    const getVisitColumns = [
        {title: "#", dataIndex: "id", key: "id"},
        {title: "Дата", render: (_, record) => { return <>{dayjs(record.docdate).format('DD.MM.YYYY')} </>; }, key: "docdate"},
        {title: "Начало", render: (_, record) => { return <>{record.dateBeg.substring(11,16)} </>; }, key: "dateBeg"},
        {title: "Окончание", render: (_, record) => { return <>{record.dateEnd.substring(11,16)} </>; }, key: "dateEnd"}
    ]

    const divUsls = <Table rowKey="name" dataSource={items} columns={getColumns()} pagination={{ position: [] }}/>
    const divVisits = <Table rowKey="id" dataSource={visits} columns={getVisitColumns} pagination={{ position: [] }}/>

    return <>
        {items && visits &&  (items.length > 0 || visits.length > 0) && <div className="history-services">
            {onlyWidth > 744 && <div className="history-services-title">{title}</div>}
            {items.length > 0 && divUsls}
            {visits.length > 0 && divVisits}
        </div>}
    </>
}

export default HistoryServices;